//form.form-validate .form-control:not(.novalidate).ng-valid.ng-dirty:not(.app-model-empty-value), //input type text
//form.form-validate .ng-select:not(.novalidate).ng-valid.ng-dirty:not(.app-model-empty-value) > div.ng-select-container, //ng select
//form.form-validate .form-select:not(.novalidate).ng-valid.ng-dirty:not(.app-model-empty-value) //select
//{
//  border-color: #198754;
//  padding-right: calc(1.5em + 0.75rem);
//  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
//  background-repeat: no-repeat;
//  background-position: right calc(0.375em + 0.1875rem) center;
//  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
//}

form.form-validate .form-control.ng-valid.ng-touched.ng-dirty:focus,
form.form-validate .form-select.ng-valid.ng-touched.ng-dirty:focus,
form.form-validate .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  //border-color: #198754;
  //box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

form.form-validate .form-control.ng-invalid.ng-touched:not(form),
form.form-validate .form-select.ng-invalid.ng-touched:not(form),
form.form-validate .ng-select.ng-invalid.ng-touched > div.ng-select-container {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

form.form-validate .form-control.ng-invalid.ng-touched:not(form):focus,
form.form-validate .form-select.ng-invalid.ng-touched:not(form):focus,
form.form-validate .ng-select.ng-invalid.ng-touched:not(form):focus > div.ng-select-container {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.ng-invalid-feedback div, .ng-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

//Radio
form.form-validate .form-check .form-check-input.ng-invalid.ng-touched.ng-dirty ~ label:after {
  content: ' ';
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
