@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/variables-dark";
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');

$font-family-base: "Noto Sans Thai";
$primary: #2592e0;
$default: #fff;
$info: #e3ebf7;
//$secondary: #4425e0;
//$warning: #e0a225;

$theme-colors: map-merge($theme-colors, (
  "primary": $primary,
  "info": $info,
  "default": $default
));

$body-bg: #ffffff;

$input-border-color: #ccc;

@import "bootstrap";
