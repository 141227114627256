/* You can add global styles to this file, and also import other style files */
@import "variables";
@import "custom_buttons";
@import "custom_forms";
@import "custom_treeview";
@import "custom_tables";
@import "custom_utility";
@import "custom_skeleton";
@import "custom_toast";
@import "custom_p-tree";
@import "bootstrap-css-validate-uncompress";
@import "validate";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

html, body {
  font-size: 0.95rem;
  background-color: #eff3f7;
}

main {
  .card {
    border: none;
    border-radius: 10px;
  }
}

span.badge.rounded-pill {
  padding-top: 0.4rem;
}

ngb-pagination .page-item {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

ngb-pagination .page-item .page-link {
  border: 0;
  border-radius: 5px;
  //width: 34px;
  //height: 34px;
  //margin-left: 0.5rem;
  //margin-right: 0.5rem;
}

ngb-pagination .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2592e0;
  border-color: #2592e0;
}

ngb-pagination .page-item.disabled .page-link {
  background-color: #eeeeee;
}

.custom-badge {
  display: inline-block;
  padding: 0.3em 0.4em;
  font-size: 90%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.15rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &.bg-primary-lt {
    color: #116bff !important;
    background-color: #e1ecff !important;
  }

  &.bg-info-lt {
    color: #1094b5 !important;
    background-color: #d9f4fb !important;
  }

  &.bg-warning-lt {
    color: #cba20a !important;
    background-color: #fdf6d9 !important;
  }

  &.bg-success-lt {
    color: #198754 !important;
    //color: #a3cfbb !important;
    background-color: #d1e7dd !important;
  }

  &.bg-danger-lt {
    color: #f21378 !important;
    background-color: #fde1ee !important;
  }

  &.bg-default-lt {
    color: #3e455a !important;
    background-color: white !important;
  }

  &.bg-secondary-lt {
    color: #3e455a !important;
    background-color: #e3e5e9 !important;
  }

  &.bg-dark-lt {
    color: #090c16 !important;
    background-color: #dadbdf !important;
  }

  &.bg-light-lt {
    background-color: #f5f5f5;
    color: #4f4f4f;
  }

  &.bg-verifying-blacklist-lt {
    background-color: #ffe8df !important;
    color: #e55a09 !important;
  }
}

.custom-text-bg-primary {
  color: #116bff !important;
  background-color: #e1ecff !important;
}

.custom-text-bg-warning {
  color: #cba20a !important;
  background-color: #fdf6d9 !important;
}

.custom-text-bg-secondary {
  color: #3e455a !important;
  background-color: #e3e5e9 !important;
}

.custom-text-bg-danger {
  color: #f21378 !important;
  background-color: #fde1ee !important;
}

.custom-bg-warning {
  color: #cba20a !important;
  background-color: #fdf6d9 !important;
}

.custom-bg-danger {
  color: #f21378 !important;
  background-color: #fde1ee !important;
}


.bg-warning {
  color: #cba20a !important;
  background-color: #fdf6d9 !important;
}

.alert-warning {
  color: #cba20a !important;
  background-color: #fdf6d9 !important;
}

//.table-primary {
//  --bs-table-color: white;
//  --bs-table-bg: $primary;
//  background: $primary;
//  color: white;
//}
.table-secondary-subtle {
}

.iframe-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch
}
.p-chips .p-chips-multiple-container .p-chips-token {
  display: inline-flex;
  padding: 0.375rem 0.75rem;
  background: #a6cdff;
  color: #000000;
  border-radius: 6px;
}

.p-disabled, .p-component:disabled {
  opacity: 1;
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
  display: none;
}
