@import "variables";

.table-info {
  //--bs-table-color: #000;
  //--bs-table-bg: $info;
  //--bs-table-border-color: #badce3;
  //--bs-table-striped-bg: #c5e8ef;
  //--bs-table-striped-color: #000;
  //--bs-table-active-bg: #badce3;
  //--bs-table-active-color: #000;
  //--bs-table-hover-bg: #bfe2e9;
  //--bs-table-hover-color: #000;
  //color: var(--bs-table-color);
  //border-color: var(--bs-table-border-color);
}

table {
  font-size: 0.875rem;

  thead {
    //border: 1px solid #374254;
    padding: 0.25rem 0.25rem;

    th {
      font-weight: normal;
    }
  }

  .btn {
    font-size: 0.8rem;
  }
}
