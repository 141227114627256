@media print{
  .non-print,.collapse-horizontal,#top-nav,#stepper-container,{
    display:none !important;
  }
  @page {
    margin: 0;
    size: auto;
  }
  body {
    margin: 1cm;
  }
}
