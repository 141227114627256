p-tree .p-checkbox-disabled .p-checkbox-box {
  //background-color: #f9f9f9 !important;

  .p-message-wrapper {
    padding: 0 !important;
  }
}

p-message, .p-message-wrapper {
  padding: 0.5rem !important;
  font-size: 0.825rem;
}
